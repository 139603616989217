import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationUnitService } from '../../../services/organisation-unit.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { OrganisationUnitModel } from '../../../models/organisation-unit/organisation-unit.model';
import { OrganisationUnitTypeModel } from '../../../models/organisation-unit/organisation-unit-type.model';
import { LoadingService } from '../../../services/loading.service';
import { editIcon } from '../../../app-icons';

@Component({
  selector: 'app-organisation-unit-overview',
  templateUrl: './organisation-unit-overview.component.html',
  styleUrls: ['./organisation-unit-overview.component.scss'],
})
export class OrganisationUnitOverviewComponent implements OnInit, OnDestroy {
  selectedOrganisationUnit$ = new BehaviorSubject<OrganisationUnitModel | null>(
    null
  );
  private subs = new Subscription();
  organisationUnits: OrganisationUnitModel[] = [];
  OrganisationUnitTypeModel = OrganisationUnitTypeModel;

  get editIcon() {
    return editIcon;
  }

  constructor(
    private organisationUnitService: OrganisationUnitService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    const obs = this.organisationUnitService.getAllOrganisationUnits();
    const sub = this.loadingService.load(obs).subscribe((organisationUnits) => {
      this.organisationUnits = organisationUnits;
      this.organisationUnits[0].parentId = null;
    });
    this.subs.add(sub);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onSelectionChanged(selectedOrganisationUnitIds: Array<any>): void {
    if (selectedOrganisationUnitIds.length == 1) {
      const organisationUnit = this.organisationUnits.filter(
        (ou) => ou.id === selectedOrganisationUnitIds[0]
      )[0];
      this.selectedOrganisationUnit$.next(organisationUnit);
    } else {
      this.selectedOrganisationUnit$.next(null);
    }
  }

  onAddClick(): void {
    this.router.navigate(['/organisation-unit/add'], {
      queryParams: { parent: this.selectedOrganisationUnit$.value?.id },
    });
  }

  onEditClick(id: any): void {
    this.router.navigate(['organisation-unit/edit/', id]);
  }

  onOrganisationUnitsInitialized(event: any) {
    event.component.forEachNode((node: any) => {
      if (node.children) {
        node.data.name += ` (${node.children.length})`;
      }
    })
  }
}
