<div>
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <div class="course-status-wrapper">
        <dx-radio-group
          [readOnly]="isViewMode"
          [items]="courseStatusOption"
          [value]="
            generalFormData.isDeployed
              ? courseStatusOption[1]
              : courseStatusOption[0]
          "
          (valueChange)="
            generalFormData.isDeployed = $event === courseStatusOption[1]
          "
          layout="horizontal"
        >
        </dx-radio-group>
        <dx-button
          class="cta-btn"
          type="normal"
          [text]="(isCreateMode ? 'COMMON.CREATE' : 'COMMON.SAVE') | translate"
          (click)="submit()"
          *ngIf="!isViewMode"
          [disabled]="isFileUploading"
        ></dx-button>
        <dx-drop-down-button
          *ngIf="isViewMode"
          [splitButton]="true"
          text="{{ 'COMMON.EDIT' | translate }}"
          stylingMode="contained"
          type="normal"
          [dropDownOptions]="{ width: 230 }"
          (onButtonClick)="navigateToEdit()"
        >
          <dxi-item (click)="navigateInviteAttendees()">
            {{ "MANAGE-ELEARNING.DETAIL.ADD-ATTENDEES" | translate }}
          </dxi-item>
          <dxi-item (click)="navigateShowFeedback()">
            {{ "MANAGE-ELEARNING.DETAIL.SHOW-FEEDBACK" | translate }}
          </dxi-item>

          <dxi-item (click)="navigateViewCourse()">
            {{ "MANAGE-ELEARNING.DETAIL.VIEW-COURSE" | translate }}</dxi-item
          >
          <dxi-item (click)="duplicateCourse(editingCourseId)">
            {{ "MANAGE-ELEARNING.DETAIL.DUPLICATE-COURSE" | translate }}
          </dxi-item>
        </dx-drop-down-button>
      </div>
    </div>
    <app-page-title
      [title]="
        'MANAGE-ELEARNING.DETAIL.' +
          (isCreateMode ? 'CREATE-TITLE' : 'EDIT-TITLE') | translate
      "
    >
    </app-page-title>
    <div class="row">
      <div class="col-md-12 col-lg-5">
        <app-panel class="avatar-panel">
          <app-video-uploader
            [readOnly]="isViewMode"
            [isExternal]="
              !!currentCourse &&
              currentCourse.courseTemplate.courseContentType ==
                CourseContentTypeModel.External
            "
            #videoUploaderComponent
            (contentFileChange)="contentFileChange($event)"
            (isUploading)="uploadingFile($event)"
            [courseId]="id"

          ></app-video-uploader>
        </app-panel>
      </div>

      <div class="col-md-12 col-lg-7">
        <app-panel class="no-top-margin">
          <dx-form
            #generalForm
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="generalFormData"
            [readOnly]="isViewMode"
          >
            <dxi-item dataField="name">
              <dxo-label [text]="'COMMON.FIELDS.TITLE' | translate"></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="
                  'MANAGE-ELEARNING.DETAIL.NAME-IS-REQUIRED' | translate
                "
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item
              dataField="description"
              editorType="dxTextArea"
              [editorOptions]="{ height: '115px' }"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.DESCRIPTION' | translate"
              ></dxo-label>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
    </div>
    <app-panel>
      <dx-tabs
        width="100%"
        [selectedIndex]="selectedDescriptionTab"
        [showNavButtons]="false"
        [dataSource]="descriptionTabs"
        (onItemClick)="onDescriptionTabChange($event)"
      ></dx-tabs>
      <div
        *ngIf="selectedDescriptionTab == 0"
        class="course-description-wrapper"
      >
        <div
          class="course-description-expand"
          (click)="toggleDescriptionExpanded()"
        >
          {{ isDescriptionExpanded ? "Collapse ↑" : "Expand ↓" }}
        </div>
        <dx-form
          labelMode="floating"
          [showColonAfterLabel]="false"
          [formData]="generalFormData"
          [readOnly]="isViewMode"
          *ngIf="generalFormData"
        >
          <dxi-item>
            <dxo-label [visible]="false"></dxo-label>
            <div *dxTemplate>
              <dx-html-editor
                #extendedDescriptionHtmlEditor
                class="html-editor"
                [height]="isDescriptionExpanded ? 'auto' : '120px'"
                [(value)]="generalFormData.descriptionExtended"
                [readOnly]="isViewMode"
              >
                <dxo-toolbar *ngIf="!isViewMode" [multiline]="true">
                  <dxi-item name="undo"></dxi-item>
                  <dxi-item name="redo"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item
                    name="size"
                    [acceptedValues]="[
                      '8pt',
                      '10pt',
                      '12pt',
                      '14pt',
                      '18pt',
                      '24pt',
                      '36pt'
                    ]"
                  ></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="bold"></dxi-item>
                  <dxi-item name="italic"></dxi-item>
                  <dxi-item name="strike"></dxi-item>
                  <dxi-item name="underline"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="alignLeft"></dxi-item>
                  <dxi-item name="alignCenter"></dxi-item>
                  <dxi-item name="alignRight"></dxi-item>
                  <dxi-item name="alignJustify"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="orderedList"></dxi-item>
                  <dxi-item name="bulletList"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item
                    name="header"
                    [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                  ></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="color"></dxi-item>
                  <dxi-item name="background"></dxi-item>
                  <dxi-item
                      name="clear"
                      [options]="htmlClearFormatOptions"
                      widget="dxButton"
                    >
                  </dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="link"></dxi-item>
                </dxo-toolbar>
              </dx-html-editor>
            </div>
          </dxi-item>
        </dx-form>
      </div>
      <div
        *ngIf="selectedDescriptionTab == 1"
        class="course-description-wrapper"
      >
        <div
          class="course-description-expand"
          (click)="toggleCertificateDescriptionExpanded()"
        >
          {{ isCertificateDescriptionExpanded ? "Collapse ↑" : "Expand ↓" }}
        </div>
        <dx-form
          labelMode="floating"
          [showColonAfterLabel]="false"
          [formData]="generalFormData"
          [readOnly]="isViewMode"
          *ngIf="generalFormData"
        >
          <dxi-item>
            <dxo-label [visible]="false"></dxo-label>
            <div *dxTemplate>
              <dx-html-editor
                #certificateDescriptionHtmlEditor
                class="html-editor"
                [height]="isCertificateDescriptionExpanded ? 'auto' : '120px'"
                [(value)]="generalFormData.descriptionCertificate"
                [readOnly]="isViewMode"
              >
                <dxo-toolbar *ngIf="!isViewMode" [multiline]="true">
                  <dxi-item name="undo"></dxi-item>
                  <dxi-item name="redo"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item
                    name="size"
                    [acceptedValues]="[
                      '8pt',
                      '10pt',
                      '12pt',
                      '14pt',
                      '18pt',
                      '24pt',
                      '36pt'
                    ]"
                  ></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="bold"></dxi-item>
                  <dxi-item name="italic"></dxi-item>
                  <dxi-item name="strike"></dxi-item>
                  <dxi-item name="underline"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="alignLeft"></dxi-item>
                  <dxi-item name="alignCenter"></dxi-item>
                  <dxi-item name="alignRight"></dxi-item>
                  <dxi-item name="alignJustify"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="orderedList"></dxi-item>
                  <dxi-item name="bulletList"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item
                    name="header"
                    [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                  ></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="color"></dxi-item>
                  <dxi-item name="background"></dxi-item>
                  <dxi-item
                      name="clear"
                      [options]="htmlCertificateClearFormatOptions"
                      widget="dxButton"
                    >
                  </dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="link"></dxi-item>
                </dxo-toolbar>
              </dx-html-editor>
            </div>
          </dxi-item>
        </dx-form>
      </div>
    </app-panel>
    <app-panel *ngIf="isViewMode">
      <dx-tabs
        width="100%"
        [showNavButtons]="false"
        [dataSource]="personsTabs"
        [selectedIndex]="0"
        (onItemClick)="onTabChange($event)"
      ></dx-tabs>
      <app-table
        [columns]="personsColumns"
        [showSearch]="true"
        [data]="(displayPersons$ | async) || []"
        [searchPlacehoder]="'COMMON.SEARCH' | translate"
      ></app-table>
    </app-panel>
    <app-panel
      [title]="'MANAGE-ELEARNING.DETAIL.TRAINING-SETTINGS' | translate"
    >
      <dx-form
        labelMode="floating"
        [showColonAfterLabel]="false"
        [formData]="trainingSettingsFormData"
        [readOnly]="isViewMode"
        #trainingSettingsForm
        *ngIf="trainingSettingsFormData"
      >
        <dxi-item itemType="group">
          <dxo-col-count-by-screen
            [xs]="1"
            [sm]="1"
            [md]="1"
            [lg]="2"
          ></dxo-col-count-by-screen>
          <dxi-item
            itemType="group"
            [caption]="
              !isViewMode
                ? ('TRAINING.FORM.GENERAL-SETTINGS' | translate)
                : null
            "
          >
            <dxi-item dataField="link">
              <dxo-label
                [text]="'MANAGE-ELEARNING.DETAIL.LINK' | translate"
              ></dxo-label>
            </dxi-item>
            <dxi-item
              *ngIf="trainingTypesOptions?.dataSource"
              dataField="trainingType"
              editorType="dxSelectBox"
              [editorOptions]="trainingTypesOptions"
            >
              <dxo-label
                [text]="'MANAGE-ELEARNING.DETAIL.TRAINING-TYPE' | translate"
              ></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'TRAINING-TYPE-IS-REQUIRED' | translate"
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item
              *ngIf="
                trainingSettingsFormData.trainingType === trainingTypeModelIDD
              "
            >
              <dxo-label
                [text]="'MANAGE-ELEARNING.DETAIL.TRAINING-TIME' | translate"
              ></dxo-label>
              <div *dxTemplate>
                <div>
                  <dx-validator
                    [adapter]="trainingTimeAdapter"
                    validationGroup="trainingTimeGroup"
                  >
                    <dxi-validation-rule
                      type="custom"
                      [validationCallback]="validateTrainingTime"
                      [message]="'VALIDATIONS.TRAINING-TIME-MINUTE' | translate"
                    ></dxi-validation-rule>
                    <dx-validation-summary></dx-validation-summary>
                  </dx-validator>
                  <div #timePicker>
                    <app-time-picker
                      [isInvalid]="!isTrainingTimeValid"
                      [disabled]="isViewMode"
                      (timeChanged)="handleTimePicker($event)"
                      [defaultValue]="{
                        hours: trainingSettingsFormData.iddHours,
                        minutes: trainingSettingsFormData.iddMinutes
                      }"
                    >
                    </app-time-picker>
                  </div>
                </div>
              </div>
            </dxi-item>
            <dxi-item
              *ngIf="publishingModeOptions?.dataSource"
              dataField="publishingMode"
              editorType="dxSelectBox"
              [editorOptions]="publishingModeOptions"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.PUBLISHING-MODE' | translate"
              ></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'TRAINING-TYPE-IS-REQUIRED' | translate"
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item
              *ngIf="
                trainingSettingsFormData.publishingMode === 0 ||
                trainingSettingsFormData.publishingMode === 1
              "
              itemType="group"
            >
              <dxi-item
                dataField="sendInfomailToEmployeesReminderDays"
                editorType="dxNumberBox"
              >
                <dxo-label
                  [text]="
                    'MANAGE-ELEARNING.DETAIL.SEND-AUTOMATIC-REMINDER-IN-DAYS'
                      | translate
                  "
                ></dxo-label>
              </dxi-item>
            </dxi-item>
            <dxi-item
              *ngIf="
                trainingSettingsFormData.trainingType === 0 &&
                learningTypeOptions?.dataSource &&
                isWbdConfigured
              "
              [editorOptions]="learningTypeOptions"
              dataField="lernart"
              editorType="dxSelectBox"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.LEARNING-TYPE' | translate"
              ></dxo-label>
            </dxi-item>
            <dxi-item
              *ngIf="
                trainingSettingsFormData.trainingType === 0 &&
                learningContentOptions?.dataSource &&
                isWbdConfigured
              "
              [editorOptions]="learningContentOptions"
              dataField="lerninhalt"
              editorType="dxSelectBox"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.LEARNING-CONTENT' | translate"
              ></dxo-label>
            </dxi-item>
          </dxi-item>
          <dxi-item
            itemType="group"
            [caption]="
              !isViewMode
                ? ('TRAINING.FORM.TARGET-GROUP-AND-CATEGORY' | translate)
                : null
            "
          >
            <dxi-item
              *ngIf="locationsOptions"
              [editorOptions]="locationsOptions"
              dataField="locationId"
              editorType="dxSelectBox"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.LOCATION' | translate"
              ></dxo-label>
              <div *dxTemplate>
                <dx-drop-down-box
                  [readOnly]="isViewMode"
                  [value]="trainingSettingsFormData.organisationUnitLocationIds"
                  valueExpr="id"
                  displayExpr="name"
                  [label]="'COMMON.FIELDS.LOCATION' | translate"
                  [dataSource]="organizationUnits || []"
                  labelMode="floating"
                  stylingMode="outlined"
                >
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view
                      [searchEnabled]="true"
                      [dataSource]="organizationUnits || []"
                      dataStructure="plain"
                      keyExpr="id"
                      selectionMode="multiple"
                      showCheckBoxesMode="normal"
                      [selectNodesRecursive]="true"
                      displayExpr="name"
                      [selectByClick]="true"
                      (onItemSelectionChanged)="onLocationsSelected($event)"
                    >
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
              </div>
            </dxi-item>
            <dxi-item
              dataField="departmentId"
              editorType="dxSelectBox"
              [editorOptions]="parentTemplatesOptions"
              *ngIf="parentTemplatesOptions"
            >
              <dxo-label
                [text]="'MANAGE-ELEARNING.DETAIL.COURSE-TEMPLATE' | translate"
              ></dxo-label>
              <div *dxTemplate>
                <dx-drop-down-box
                  [readOnly]="isViewMode"
                  [value]="trainingSettingsFormData.parentTemplateIds"
                  [inputAttr]="{ 'aria-label': 'Owner' }"
                  valueExpr="id"
                  displayExpr="name"
                  [label]="
                    'MANAGE-ELEARNING.DETAIL.COURSE-TEMPLATE' | translate
                  "
                  [dataSource]="parentTemplatesOptions.dataSource || []"
                  labelMode="floating"
                  stylingMode="outlined"
                >
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view
                      [searchEnabled]="true"
                      [dataSource]="parentTemplatesOptions.dataSource || []"
                      dataStructure="plain"
                      keyExpr="id"
                      selectionMode="multiple"
                      showCheckBoxesMode="normal"
                      [selectNodesRecursive]="false"
                      displayExpr="name"
                      [selectByClick]="true"
                      (onItemSelectionChanged)="
                        onParentTemplatesSelected($event)
                      "
                    >
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
              </div>
            </dxi-item>
            <dxi-item *ngIf="trainingGroupsOptions">
              <dxo-label
                [text]="'MANAGE-ELEARNING.DETAIL.TRAINING-GROUPS' | translate"
              ></dxo-label>
              <div *dxTemplate>
                <dx-drop-down-box
                  [value]="trainingSettingsFormData.trainingGroupIds"
                  [inputAttr]="{ 'aria-label': 'Owner' }"
                  valueExpr="id"
                  displayExpr="name"
                  [readOnly]="isViewMode"
                  [label]="
                    'MANAGE-ELEARNING.DETAIL.TRAINING-GROUPS' | translate
                  "
                  [dataSource]="trainingGroupsOptions.dataSource || []"
                  labelMode="floating"
                  stylingMode="outlined"
                >
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view
                      [searchEnabled]="true"
                      [dataSource]="trainingGroupsOptions.dataSource || []"
                      dataStructure="plain"
                      keyExpr="id"
                      selectionMode="multiple"
                      showCheckBoxesMode="normal"
                      [selectNodesRecursive]="false"
                      displayExpr="name"
                      [selectByClick]="true"
                      (onItemSelectionChanged)="onTrainingGroupSelected($event)"
                    >
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
              </div>
            </dxi-item>
            <dxi-item *ngIf="trainingCategoriesOptions">
              <dxo-label
                [text]="
                  'MANAGE-ELEARNING.DETAIL.TRAINING-CATEGORIES' | translate
                "
              ></dxo-label>
              <div *dxTemplate>
                <dx-drop-down-box
                  [value]="trainingSettingsFormData.trainingCategoryIds"
                  [inputAttr]="{ 'aria-label': 'Owner' }"
                  valueExpr="id"
                  displayExpr="name"
                  [readOnly]="isViewMode"
                  [label]="
                    'MANAGE-ELEARNING.DETAIL.TRAINING-CATEGORIES' | translate
                  "
                  [dataSource]="trainingCategoriesOptions.dataSource || []"
                  labelMode="floating"
                  stylingMode="outlined"
                >
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view
                      [searchEnabled]="true"
                      [dataSource]="trainingCategoriesOptions.dataSource || []"
                      dataStructure="plain"
                      keyExpr="id"
                      selectionMode="multiple"
                      showCheckBoxesMode="normal"
                      [selectNodesRecursive]="false"
                      displayExpr="name"
                      [selectByClick]="true"
                      (onItemSelectionChanged)="
                        onTrainingCategorySelected($event)
                      "
                    >
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
              </div>
            </dxi-item>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" colCount="2" cssClass="checkboxes-wrapper">
          <dxi-item
            editorType="dxCheckBox"
            dataField="sendCertificateLink"
            [editorOptions]="{
                text: 'COMMON.FIELDS.CERTIFICATE-LINK-IN-COMPLETION-MAIL' | translate,
              }"
          >
            <dxo-label [visible]="false"> </dxo-label>
          </dxi-item>
          <dxi-item
            editorType="dxCheckBox"
            dataField="sendFeedbackLink"
            [editorOptions]="{
              text: 'COMMON.FIELDS.FEEDBACK-LINK-IN-COMPLETION-MAIL' | translate,
            }"
          >
            <dxo-label [visible]="false"> </dxo-label>
          </dxi-item>
        </dxi-item>
      </dx-form>
    </app-panel>
    <app-panel
      [title]="'MANAGE-ELEARNING.DETAIL.LEARNING-CONTROL-QUESTIONS' | translate"
    >
      <div>
        {{
          "MANAGE-ELEARNING.DETAIL.ALL-QUESTIONS-HERE-ARE-MANDATORY-TO-BE-ANSWERED"
            | translate
        }}
      </div>
      <div class="row question-row">
        <div class="col">
          <dx-form
            #questionsForm
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="questionsFormData"
            [readOnly]="isViewMode"
            *ngIf="questionsFormData"
          >
            <dxi-item itemType="group" name="questions" colSpan="2">
              <dxi-item
                itemType="group"
                *ngFor="
                  let question of questionsFormData.questions;
                  let i = index
                "
              >
                <dxi-item itemType="group" colCount="3">
                  <dxi-item
                    [dataField]="'questions[' + i + '].question'"
                    colSpan="2"
                  >
                    <dxo-label
                      [text]="
                        'MANAGE-ELEARNING.DETAIL.TYPE-QUESTION-HERE' | translate
                      "
                    ></dxo-label>
                  </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" colCount="3">
                  <dxi-item
                    itemType="group"
                    [caption]="
                      'MANAGE-ELEARNING.DETAIL.ANSWER-OPTIONS' | translate
                    "
                    colSpan="2"
                  >
                    <dxi-item
                      *ngFor="let option of question.options; let j = index"
                      [dataField]="
                        'questions[' + i + '].options[' + j + '].option'
                      "
                      [editorOptions]="{
                        placeholder:
                          'MANAGE-ELEARNING.DETAIL.TYPE-ANSWER-HERE' | translate
                      }"
                      [cssClass]="'question-count-label'"
                    >
                      <dxo-label
                        location="left"
                        [text]="j + 1 + '.'"
                        req
                      ></dxo-label>
                    </dxi-item>
                    <dxi-item itemType="group" colCount="3">
                      <dxi-item>
                        <dxo-label [visible]="false"></dxo-label>
                        <div *dxTemplate>
                          <dx-button
                            [disabled]="isViewMode"
                            [text]="
                              'MANAGE-ELEARNING.DETAIL.ADD-OPTION' | translate
                            "
                            stylingMode="text"
                            type="default"
                            class="integration-btn"
                            (click)="addQuestionOption(i)"
                          >
                          </dx-button>
                        </div>
                      </dxi-item>
                      <dxi-item
                        *ngIf="
                          questionsFormData &&
                          questionsFormData.questions[i].options.length > 3
                        "
                      >
                        <dxo-label [visible]="false"></dxo-label>
                        <div *dxTemplate>
                          <dx-button
                            [disabled]="isViewMode"
                            [text]="
                              'MANAGE-ELEARNING.DETAIL.REMOVE-OPTION'
                                | translate
                            "
                            stylingMode="text"
                            type="danger"
                            (click)="removeQuestionOption(i)"
                          >
                          </dx-button>
                        </div>
                      </dxi-item>
                    </dxi-item>
                  </dxi-item>
                  <dxi-item
                    itemType="group"
                    [caption]="
                      'MANAGE-ELEARNING.DETAIL.INDICATE-THE-RIGHT-ANSWER'
                        | translate
                    "
                  >
                    <dxi-item>
                      <dxo-label [visible]="false"></dxo-label>
                      <div *dxTemplate>
                        <dx-radio-group
                          [readOnly]="isViewMode"
                          [dataSource]="question.options"
                          displayExpr="index"
                          valueExpr="index"
                          (valueChange)="selectCorrectAnswer(i, $event)"
                          class="question-radio"
                          [value]="question.answerIndex"
                        >
                        </dx-radio-group>
                      </div>
                    </dxi-item>
                  </dxi-item>
                </dxi-item>
              </dxi-item>
            </dxi-item>
          </dx-form>
        </div>
      </div>
      <div class="questions-footer">
        <dx-button
          [text]="'MANAGE-ELEARNING.DETAIL.REMOVE-QUESTION' | translate"
          type="default"
          class="integration-btn"
          (click)="removeQuestion()"
          [disabled]="isViewMode || questionsFormData.questions.length < 2"
          *ngIf="questionsFormData"
        >
        </dx-button>
        <dx-button
          [text]="'MANAGE-ELEARNING.DETAIL.ADD-QUESTION' | translate"
          icon="add"
          class="integration-btn"
          [disabled]="isViewMode"
          (onClick)="addQuestion()"
        >
        </dx-button>
      </div>
    </app-panel>
  </app-page>
</div>
