import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { IntegrationsService } from '../../services/integration/integrations.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IntegrationModel,
  WbdModel,
} from '../../models/integration/integration.model';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { IntegrationTypeModel } from '../../models/integration/integration-type.model';
import { ToastrService } from 'ngx-toastr';
import { TrainingLocationTypeModel } from '../../models/training/training-location-type.model';
import { TranslateService } from '@ngx-translate/core';
import dxMenuBase from 'devextreme/ui/context_menu/ui.menu_base';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  private reloadIntegrations$ = new BehaviorSubject(null);
  integrations$: Observable<IntegrationModel[]>;
  webexIntegration$: Observable<IntegrationModel | undefined>;
  zoomIntegration$: Observable<IntegrationModel | undefined>;
  wbdIntegration$: Observable<boolean>;
  teamsIntegration$: Observable<IntegrationModel | undefined>;
  goToWebinarIntegration$: Observable<IntegrationModel | undefined>;
  disconnectModalVisible: boolean = false;
  disconnectType: string | null = null;
  showIntegrateWbdModal: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private integrationService: IntegrationsService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
    this.integrations$ = this.reloadIntegrations$.pipe(
      switchMap(() =>
        this.loadingService.load(this.integrationService.getIntegrations())
      ),
      shareReplay(1)
    );
    this.webexIntegration$ = this.integrations$.pipe(
      map((integrations) =>
        integrations.find(
          (integration) => integration.type == IntegrationTypeModel.Webex
        )
      )
    );
    this.goToWebinarIntegration$ = this.integrations$.pipe(
      map((integrations) =>
        integrations.find(
          (integration) => integration.type == IntegrationTypeModel.GoToWebinar
        )
      )
    );
    this.teamsIntegration$ = this.integrations$.pipe(
      map((integrations) =>
        integrations.find(
          (integration) => integration.type == IntegrationTypeModel.MsTeams
        )
      )
    );
    this.wbdIntegration$ = this.integrations$.pipe(
      switchMap(() =>
        this.loadingService.load(this.integrationService.getWbd())
      ),
      shareReplay(1)
    );

    this.zoomIntegration$ = this.integrations$.pipe(
      map((integrations) =>
        integrations.find(
          (integration) => integration.type == IntegrationTypeModel.Zoom
        )
      )
    );
  }

  ngOnInit(): void {}

  // Webex
  integrateWebex(): void {
    debugger
    const obs = this.integrationService.getOAuthUrl(
      TrainingLocationTypeModel.Webex,
      IntegrationsService.webexRedirectUrl
    );
    this.loadingService
      .load(obs)
      .subscribe((url) => (window.location.href = url));
  }

  disconnectWebex(): void {
    this.loadingService
      .load(this.integrationService.disconnect(TrainingLocationTypeModel.Webex))
      .subscribe(() => {
        this.toastrService.success('Webex Integration aufgehoben');
        this.refreshIntegrations();
      });
  }
  // Team
  integrateTeams(): void {
    const obs = this.integrationService.getOAuthUrlTeam(
      TrainingLocationTypeModel.MsTeams,
      IntegrationsService.teamRedirectUrl
    );
    this.loadingService
      .load(obs)
      .subscribe((url) => (window.location.href = url));
  }
  disconnectMsTeams(): void {
    this.loadingService
      .load(this.integrationService.disconnect(TrainingLocationTypeModel.MsTeams))
      .subscribe(() => {
        this.toastrService.success('Teams Integration aufgehoben');
        this.refreshIntegrations();
      });
  }
  // GoToWebinar
  integrateGoToWebinar(): void {
    const obs = this.integrationService.getOAuthUrl(
      TrainingLocationTypeModel.GoToWebinar,
      IntegrationsService.goToWebinarRedirectUrl
    );
    this.loadingService
      .load(obs)
      .subscribe((url) => (window.location.href = url));
  }


  disconnectGoToWebinar(): void {
    this.loadingService
      .load(
        this.integrationService.disconnect(
          TrainingLocationTypeModel.GoToWebinar
        )
      )
      .subscribe(() => {
        this.toastrService.success('GoToWebinar Integration aufgehoben');
        this.refreshIntegrations();
      });
  }
 // Zoom
 integrateZoom(): void {
  debugger
  const obs = this.integrationService.getOAuthUrl(
    TrainingLocationTypeModel.Zoom,
    IntegrationsService.goToZoomRedirectUrl
  );

  this.loadingService
    .load(obs)
    .subscribe((url) =>{debugger; (window.location.href = url)});
}
disconnectZoom(): void {
  this.loadingService
    .load(this.integrationService.disconnect(TrainingLocationTypeModel.Zoom))
    .subscribe(() => {
      this.toastrService.success('Zoom Integration aufgehoben');
      this.refreshIntegrations();
    });
}
  showIntegrateWbd(): void {
    this.showIntegrateWbdModal = true;
  }

  integrateWbd(wdbModel: WbdModel): void {
    const obs = this.integrationService.configureWbd(wdbModel);
    this.loadingService.load(obs).subscribe((url) => {
      this.toastrService.success(
        this.translateService.instant('INTEGRATIONS.GUTBERATEN-CONNECTED')
      );
      this.refreshIntegrations();
    });
  }

  disconnectWbd(): void {
    this.loadingService
      .load(this.integrationService.disconnectWbd())
      .subscribe(() => {
        this.toastrService.success(
          this.translateService.instant('INTEGRATIONS.GUTBERATEN-CANCELLED')
        );
        this.refreshIntegrations();
      });
  }

  showDisconnectModal(type: string): void {
    this.disconnectType = type;
    this.disconnectModalVisible = true;
  }

  closeDisconnectModal(): void {
    this.disconnectModalVisible = false;
    this.disconnectType = null;
  }

  disconnectIntegration(): void {
    if (this.disconnectType == 'webex') {
      this.disconnectWebex();
    } else if (this.disconnectType == 'gotowebinar') {
      this.disconnectGoToWebinar();
    } else if (this.disconnectType == 'gutberaten') {
      this.disconnectWbd();
    } else if (this.disconnectType == 'msTeams') {
      this.disconnectMsTeams();
    }else if (this.disconnectType == 'zoom') {
      this.disconnectZoom();
    }
    this.disconnectType = null;
    this.disconnectModalVisible = false;
  }

  private refreshIntegrations(): void {
    this.reloadIntegrations$.next(null);
  }
}
